import React, { useContext, useEffect } from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Seo from "../components/services/seo"
import DynamicComponent from "../components/services/dynamicComponent"
import useStoryblok from "../lib/storyblok"

import { CurrentPageContext } from "../context"

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  const siteData = data.site.siteMetadata;

  const { currentPage, setCurrentPage } = useContext(CurrentPageContext);
  useEffect(() => {
    setCurrentPage([{
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])

  story = useStoryblok(story, location)
  let allImages = [];
  const components = story.content.body.map(blok => {
    if (blok.component == "ContentBlock") {
      if (blok.image && blok.image.filename) {
        allImages.push(blok.image);
      }
      if (blok.image_two && blok.image_two.filename) {
        allImages.push(blok.image_two);
      }
    }
    if (blok.component == "infobanners") {
      blok.items.map(item => {
        if (item.image && item.image.filename) {
          allImages.push(item.image);
        }
        return item;
      })
    }
    if (blok.component == "teaserscroller") {
      blok.items.map(item => {
        if (item.image && item.image.filename) {
          allImages.push(item.image);
        }
        return item;
      })
    }
    return (<DynamicComponent blok={blok} data={data} key={blok._uid} />)
  })

  allImages = allImages.map(item => {
    return {
      "@type": "imageObject",
      "url": item.filename.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk')
    }
  });

  const schema = story.content.schema_markup;

  return (
    <SbEditable content={story.content}>
      <Seo title={story.content.title_meta} description={story.content.description_meta} schemaMarkup={schema} url={location.href} />
      {components}
    </SbEditable>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: {eq: "home"}) {
      content
      name
    }
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        name
        logo
        phone
        address
        city
        postalCode
        country
      }
    }
    allImageSharp {
       edges {
         node {
           gatsbyImageData(
             width: 200
             placeholder: BLURRED
             formats: [AUTO, WEBP, AVIF]
             quality:50
           )
         }
       }
     }
  }`
